<template>
  <drop-down icon="" tag="li"
              position="right"
              direction="none" 
              class="nav-item btn-rotate dropdown">
              <!-- v-if="accounts.length > 1 || hasRight(RIGHT_EDIT_ACCOUNT)"> -->
    <a slot="title"
        slot-scope="{isOpen}"
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        :aria-expanded="isOpen">
      <!-- <i class="fa fa-gear"></i> -->
      <p>
        <!-- <span class="d-md-block" v-if="!isSystemAdministrator">My Workspaces&nbsp;</span>
        <span class="d-md-block" v-if="isSystemAdministrator">Recent Workspaces&nbsp;</span> -->
        <span class="d-md-block" v-if="!isProviderAccount">Workspace&nbsp;</span>
        <span class="d-md-block" v-if="isProviderAccount">Namespace&nbsp;</span>
      </p>
    </a>
    <router-link :to="{name: 'my.documents'}" class="dropdown-item">My Documents</router-link>
    
    <router-link v-if="hasRight(RIGHT_EDIT_ACCOUNT) && !isProviderAccount" :to="{name: 'admin.account'}" class="dropdown-item text-teal">Manage Workspace</router-link>
    <router-link v-if="hasRight(RIGHT_EDIT_ACCOUNT) && isProviderAccount" :to="{name: 'admin.account'}" class="dropdown-item text-teal">Manage Namespace</router-link>
    <router-link v-if="hasRight(RIGHT_EDIT_ACCOUNT)" :to="{name: 'admin.subscription'}" class="dropdown-item text-teal">Manage Subscription</router-link>
    <hr v-if="hasRight(RIGHT_EDIT_ACCOUNT)" class="mt-0 mb-2" />
    <router-link :to="{name: 'admin.workspaces'}" class="dropdown-item text-teal">My Workspaces</router-link>

    <li class="dropdown-item-subhead" v-if="accounts.length > 1">Recent Workspaces</li>

    <a v-for="(account, index) in sortedAccounts"
      :key="account.name + index"
      @click="switchToAccount(account.accountId)"
      class="dropdown-item" href="#"><span v-if="isSystemAdministrator">{{truncateNameAndParent(account)}}</span><span v-else>{{account.name}}</span></a>
  </drop-down>
</template>

<script>
import { 
    RIGHT_EDIT_ACCOUNT
} from "@/helpers";
import { 
        AUTHENTICATION_SWITCH_ACCOUNT,
    } from "@/store/actions.type";

import { mapState, mapGetters } from "vuex";

export default {
  props: {

  },
  components: {

  },
  data() {
    return {
      RIGHT_EDIT_ACCOUNT
    };
  },
  computed: {
    ...mapState("account", {
        currentAccount: state => state.currentAccount,
        accounts: state => state.recentAccounts
    }),
    ...mapGetters("account", [
          "isProviderAccount"
        ]),
    ...mapGetters("authentication", [
        "hasRight", "isSystemAdministrator"
    ]),
    sortedAccounts: {
      get: function() {
        // don't show the accounts if there is only 1
        if (this.accounts.length == 1) {
          return [];
        }
        return _.sortBy(this.accounts, ["name"]);
      }
    },
  },
  methods: {
    truncateNameAndParent(account) {
        if (!account.parentAccountName)
          return account.name;
        
        const combinedName = `${account.name} (${account.parentAccountName})`;
        
        if (combinedName.length > 60) {
          return `${combinedName.substring(0, 60)}...`;
        }
        
        return combinedName;
    },
    async switchToAccount(accountId) {
        try {
            await this.$store.dispatch(AUTHENTICATION_SWITCH_ACCOUNT, accountId)
            // pause 
            await new Promise(r => setTimeout(r, 1000));

            // reload main page, this refreshes the store as well
            window.location.assign(process.env.VUE_APP_WWW_BASE_URL);

        } catch (error) {
            console.log(error);
        }
    },
  },
};
</script>
