import _, { max } from 'lodash';
import Vue from 'vue';
import { accountService } from '@/services';
import { GUID_EMPTY, ACCOUNT_TYPE_CLIENT, ACCOUNT_TYPE_CERTIFIED_PROVIDER, ACCOUNT_TYPE_PARTNER } from '@/helpers';
import { v4 as uuidv4 } from 'uuid';
import {
    GET_REFERENCE_VALUES,
    GET_FRAMEWORKS,
    GET_FLAT_FRAMEWORKS,
    GET_ALL,
    GET_MY_WORKSPACES,
    GET,
    GET_SUBSCRIPTION,
    GET_RECENT,
    UPDATE,
    UPDATE_DOMAINS,
    UPDATE_TRIAL,
    UPDATE_DEMO,
    UPDATE_CERTIFIED_PROVIDER,
    UPDATE_BILLING_DAY,
    REMOVE_USER,
    INVITE_USER,
    RESEND_INVITE,
    SETUP_SUBSCRIPTION_PLAN,
    SETUP_SUBSCRIPTION_ONBOARDING_INVOICE,
    SETUP_SUBSCRIPTION_DISCOUNT,
    SETUP_SUBSCRIPTION_BILLING_DETAILS,
    SETUP_SUBSCRIPTION_PAYMENT,
    SETUP_SUBSCRIPTION_COMPLETE,
    CANCEL_INVITE,
    SETUP_ACCOUNT,
    SETUP_PORTFOLIO,
    SETUP_APPLICATIONS,
    SETUP_AREAS,
    SETUP_CONTACTS,
    SETUP_SKIP,
    SWITCH_SUBSCRIPTION_PLAN,
    SET_PAYMENT_METHOD    
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    SET_SELECTED_ACCOUNT,
    GET_REFERENCE_VALUES_REQUEST,
    GET_REFERENCE_VALUES_SUCCESS,
    GET_REFERENCE_VALUES_FAILURE,
    GET_FRAMEWORKS_REQUEST,
    GET_FRAMEWORKS_SUCCESS,
    GET_FRAMEWORKS_FAILURE,
    GET_FLAT_FRAMEWORKS_REQUEST,
    GET_FLAT_FRAMEWORKS_SUCCESS,
    GET_FLAT_FRAMEWORKS_FAILURE,
    GET_MY_WORKSPACES_REQUEST,
    GET_MY_WORKSPACES_SUCCESS,
    GET_MY_WORKSPACES_FAILURE,
    UPDATE_CURRENT_ITEM,
    UPDATE_ADDRESS,

    ADD_DOMAIN,
    SAVE_DOMAIN,
    REMOVE_DOMAIN,
    SET_CURRENT_DOMAIN,
    CLEAR_CURRENT_DOMAIN,
    UPDATE_CURRENT_DOMAIN,

    GET_REQUEST,
    GET_FAILURE,
    GET_SUCCESS,
    GET_SUBSCRIPTION_REQUEST,
    GET_SUBSCRIPTION_FAILURE,
    GET_SUBSCRIPTION_SUCCESS,
    REMOVE_USER_REQUEST,
    REMOVE_USER_FAILURE,
    REMOVE_USER_SUCCESS,
    INVITE_USER_REQUEST,
    INVITE_USER_FAILURE,
    INVITE_USER_SUCCESS,
    RESEND_INVITE_REQUEST,
    RESEND_INVITE_FAILURE,
    RESEND_INVITE_SUCCESS,
    CANCEL_INVITE_REQUEST,
    CANCEL_INVITE_FAILURE,
    CANCEL_INVITE_SUCCESS,
    SETUP_REQUEST,
    SETUP_FAILURE,
    SETUP_SUCCESS,
    GET_RECENT_REQUEST,
    GET_RECENT_FAILURE,
    GET_RECENT_SUCCESS,

    SWITCH_SUBSCRIPTION_REQUEST,
    SWITCH_SUBSCRIPTION_SUCCESS,
    SWITCH_SUBSCRIPTION_FAILURE,

    SET_PAYMENT_METHOD_REQUEST,
    SET_PAYMENT_METHOD_SUCCESS,
    SET_PAYMENT_METHOD_FAILURE    
  } from "./mutations.type";

const blankReferenceValues = {
    technologyQuestionCounts: 0,
    technologyQuestionsPointWeight: 0,
    technologyQuestions: [],
    businessQuestions: [],
    businessAreas: [],
    contacts: [],
    contactPositions: [],
    companies: [],
    applicationStates: [],
    //areaTypes: [],
    //contactTypes: [],
    technologyTypes: [],
    applicationTypes: [],
    //projectNoteStatuses: [],
    //projectNoteTypes: [],
    projectTypes: [],
    projectSizes: [],
    projectStages: [],
    projectStatuses: [],
    integrationTypes: [],
    portfolios: [],
    tags: [],
    integrationDirections: [],
    projects: [],
    programs: [],
    tags: [],
    classifications: [],
    dataClassifications: [],
    computingTypes: [],
    platforms: [],
    drivers: []
};

function getBlankAccount() {
    return {
        accountId: GUID_EMPTY,
        parentAccountId: null,
        addressId: null,
        accountType: {},
        name: "",
        workspaceName: null,
        isAutomaticEnrollmentEnabled: false,
        emailAddress: null,
        websiteAddress: null,
        contactPhone: null,
        timeZone: null,
        logoFilename: null,
        thumbnailLogoFilename: null,
        iconFilename: null,
        billingPeriodStartDay: null,
        isDemoAccount: false,
        isTrialAccount: false,
        trialExpiryDate: null,
        isTrialExpired: false,
        minBilledEditUsers: null,
        maxBilledEditUsers: null,
        maxEditUserAccounts: null,
        onlyCertifiedProviderCanAddEditUsers: false,
        onlyCertifiedProviderCanAddViewUsers: false,
        refCompanyRevenueId: null,
        refCompanyEmployeeId: null,
        refCompanyTypeId: null,
        refIndustryId: null,
        refQuestionLevelId: null,
        currentSetupStep: null,
        currentSubscriptionSetupStep: null,
        technologyQuestionsCount: 0,
        technologyQuestionsPointWeight: 0,
        lastApplicationColorId: 0,
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        address: getBlankAddress(),
        accountDomains: [],
        parentAccountName: null,
        parentAccountNumber: null,
        parentAccountSubscriptionLevel: null,
        parentAccountBillsChildAccountsDirectly: false
    };
}

function getBlankAddress() {
    return {
        addressId: GUID_EMPTY,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
        city: null,
        province: null,
        countryName: null,
        countryId: null,
        postalCode: null
    };
}

function getBlankDomain() {
    return {
        accountDomainId: GUID_EMPTY,
        domainName: null,
        allowAutomaticEnrollment: true
    };
}

function getBlankWorkspaces() {
    return {
        certifiedProviderAccounts: [],
        availableAccounts: [],
        memberOfAccounts: []
    };
}

function getBlankAccountSubscription() {
    return {
        accountSubscription: {
            accountProductId: GUID_EMPTY,
            accountId: null,
            productId: null,
            effectiveDate: null,
            // expiryDate: null,
            endDate: null,
            productStatus: null,
            lastTransactionDate: null,
            lastTransactionAmount: null,
            lastTransactionPeriodStartDate: null,
            lastTransactionPeriodEndDate: null,
            createdDate: null,
            createdBy: null,
            createdByName: null,
            modifiedDate: null,
            modifiedBy: null,
            modifiedByName: null,
            product: {
                productId: GUID_EMPTY,
                productCode: null,
                productType: null,
                taxProductType: null,
                name: null,
                defaultExpiryMonths: null,
                defaultExpiryDays: null,
                requiresConsult: false,
                isHidden: false,
                displayOrder: null,
                effectiveDate: null,
                endDate: null
            },
            rateForNextTransaction: {
                productRateId: GUID_EMPTY,
                unit: null,
                rate: null,
                isPerEditUser: false,
                effectiveDate: null,
                endDate: null
            },
        },
        paymentMethod: {
            accountPaymentMethodId: null,
            accountId: null,
            paymentType: null,
            description: null,
            notes: null,
            effectiveDate: null,
            endDate: null
        },
        enabledModules: [],
        invoices: [],
        accountLimits: {
            maxReadOnlyUsers: null,
            maxEditUsers: null,
            maxIdeas: null,
            maxPrograms: null,
            maxProjects: null,
            maxPortfolios: null,
            maxApplications: null,
            maxITServices: null,
            maxContacts: null,
            maxClientWorkspaces: null
        }
    };
}

export const account = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        referenceValues: blankReferenceValues,
        frameworks: [],
        flatFrameworks: [],
        selectedAccountId: null,
        currentAccount: getBlankAccount(),
        currentSubscription: getBlankAccountSubscription(),
        currentAccountDomain: getBlankDomain(),
        myWorkspaces: getBlankWorkspaces(),
        items: [],
        recentAccounts: []
    },
    getters: {
        isClientAccount: (state) => {
            return state.currentAccount 
                && state.currentAccount.accountType 
                && state.currentAccount.accountType.accountTypeId == ACCOUNT_TYPE_CLIENT;
        },
        isProviderAccount: (state) => {
            // debugger;
            return state.currentAccount 
                && state.currentAccount.accountType 
                && (state.currentAccount.accountType.accountTypeId == ACCOUNT_TYPE_CERTIFIED_PROVIDER || state.currentAccount.accountType.accountTypeId == ACCOUNT_TYPE_PARTNER);
        },

    },
    actions: {
        [GET_ALL]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                return accountService.getAll()
                    .then(
                        accounts => {
                            commit(GET_ALL_SUCCESS, accounts);
                            resolve(accounts);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_RECENT]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_RECENT_REQUEST);

                return accountService.getRecent()
                    .then(
                        accounts => {
                            commit(GET_RECENT_SUCCESS, accounts);
                            resolve(accounts);
                        },
                        error => {
                            commit(GET_RECENT_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_REFERENCE_VALUES]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.referenceValues.applicationTypes.length > 0) {
                    resolve(state.referenceValues);
                } else {
                    commit(GET_REFERENCE_VALUES_REQUEST);

                    return accountService.getReferenceValues(this.selectedAccountId)
                        .then(
                            data => {
                                commit(GET_REFERENCE_VALUES_SUCCESS, data);
                                resolve(data);
                            },
                            error => {
                                commit(GET_REFERENCE_VALUES_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_FRAMEWORKS]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.frameworks.length > 0) {
                    resolve(state.frameworks);
                } else {
                    commit(GET_FRAMEWORKS_REQUEST);

                    return accountService.getFrameworks()
                        .then(
                            data => {
                                commit(GET_FRAMEWORKS_SUCCESS, data);
                                resolve(data);
                            },
                            error => {
                                commit(GET_FRAMEWORKS_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_FLAT_FRAMEWORKS]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.flatFrameworks.length > 0) {
                    resolve(state.flatFrameworks);
                } else {
                    commit(GET_FLAT_FRAMEWORKS_REQUEST);

                    return accountService.getFlatFrameworks()
                        .then(
                            data => {
                                commit(GET_FLAT_FRAMEWORKS_SUCCESS, data);
                                resolve(data);
                            },
                            error => {
                                commit(GET_FLAT_FRAMEWORKS_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_MY_WORKSPACES]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.referenceValues.applicationTypes.length > 0) {
                    resolve(state.referenceValues);
                } else {
                    commit(GET_MY_WORKSPACES_REQUEST);

                    return accountService.getWorkspaces()
                        .then(
                            data => {
                                commit(GET_MY_WORKSPACES_SUCCESS, data);
                                resolve(data);
                            },
                            error => {
                                commit(GET_MY_WORKSPACES_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },        
        [GET]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_REQUEST);

                return accountService.getCurrent()
                    .then(
                        account => {
                            commit(GET_SUCCESS, account);
                            resolve(account);
                        },
                        error => {
                            commit(GET_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_SUBSCRIPTION]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                    commit(GET_SUBSCRIPTION_REQUEST);

                    return accountService.getAccountSubscription()
                        .then(
                            data => {
                                commit(GET_SUBSCRIPTION_SUCCESS, data);
                                resolve(data);
                            },
                            error => {
                                commit(GET_SUBSCRIPTION_FAILURE, error);
                                reject(error);
                            }
                    );
                });
            },        
        [UPDATE]({ dispatch, commit }, { account, file }) {
            return accountService.update(account, file);
        },
        [UPDATE_DOMAINS]({ dispatch, commit }, domains) {
            return accountService.updateDomains(domains);
        },
        [UPDATE_TRIAL]({ dispatch, commit }, account ) {
            return accountService.updateTrial(account);
        },
        [UPDATE_DEMO]({ dispatch, commit }, account ) {
            return accountService.updateDemo(account);
        },
        [UPDATE_CERTIFIED_PROVIDER]({ dispatch, commit }, account ) {
            return accountService.updateCertifiedProvider(account);
        },
        [UPDATE_BILLING_DAY]({ dispatch, commit }, account ) {
            return accountService.updateBillingDay(account);
        },
        
        [INVITE_USER]({ commit }, { firstName, lastName, emailAddress, company, position, contactPhone, roleName, portfolioRoles }) {
            return new Promise((resolve, reject) => {
                commit(INVITE_USER_REQUEST);

                accountService.inviteUser(firstName, lastName, emailAddress, company, position, contactPhone, roleName, portfolioRoles)
                    .then(
                        response => {
                            commit(INVITE_USER_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(INVITE_USER_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [RESEND_INVITE]({ commit }, userId) {
            return new Promise((resolve, reject) => {
                commit(RESEND_INVITE_REQUEST);

                accountService.resendInvite(userId)
                    .then(
                        response => {
                            commit(RESEND_INVITE_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(RESEND_INVITE_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [CANCEL_INVITE]({ commit }, userId) {
            return new Promise((resolve, reject) => {
                commit(CANCEL_INVITE_REQUEST);

                accountService.cancelInvite(userId)
                    .then(
                        response => {
                            commit(CANCEL_INVITE_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(CANCEL_INVITE_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        
        [REMOVE_USER]({ commit }, userId) {
            return new Promise((resolve, reject) => {
                commit(REMOVE_USER_REQUEST);

                accountService.removeUser(userId)
                    .then(
                        response => {
                            commit(REMOVE_USER_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(REMOVE_USER_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },

        [SETUP_SUBSCRIPTION_PLAN]({ commit }, { planCode, annualPricing }) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupSubscriptionPlan(planCode, annualPricing)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },

        [SWITCH_SUBSCRIPTION_PLAN]({ commit }, { planCode, annualPricing }) {
            return new Promise((resolve, reject) => {
                commit(SWITCH_SUBSCRIPTION_REQUEST);

                return accountService.switchSubscriptionPlan(planCode, annualPricing)
                    .then(
                        data => {
                            // returns updated subscription details
                            commit(SWITCH_SUBSCRIPTION_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SWITCH_SUBSCRIPTION_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },

        [SET_PAYMENT_METHOD]({ commit }, setupIntent ) {
            return new Promise((resolve, reject) => {
                commit(SET_PAYMENT_METHOD_REQUEST);

                return accountService.setPaymentMethod(setupIntent)
                    .then(
                        data => {
                            // returns updated subscription details
                            commit(SET_PAYMENT_METHOD_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SET_PAYMENT_METHOD_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },



        [SETUP_SUBSCRIPTION_ONBOARDING_INVOICE]({ commit }, invoiceOnboarding) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupSubscriptionOnboardingInvoice(invoiceOnboarding)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },

        [SETUP_SUBSCRIPTION_DISCOUNT]({ commit }, discountCode) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupSubscriptionDiscount(discountCode)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },

        [SETUP_SUBSCRIPTION_BILLING_DETAILS]({ commit }, account) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupSubscriptionBillingDetails(account)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [SETUP_SUBSCRIPTION_PAYMENT]({ commit }, paymentIntent) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupSubscriptionPayment(paymentIntent)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [SETUP_SUBSCRIPTION_COMPLETE]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupSubscriptionComplete()
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },

        [SETUP_ACCOUNT]({ commit }, account) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupAccount(account)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [SETUP_PORTFOLIO]({ commit }, portfolio) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupPortfolio(portfolio)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [SETUP_APPLICATIONS]({ commit }, applications) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupApplications(applications)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [SETUP_AREAS]({ commit }, areas) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupAreas(areas)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [SETUP_CONTACTS]({ commit }, contacts) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupContacts(contacts)
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [SETUP_SKIP]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(SETUP_REQUEST);

                return accountService.setupSkip()
                    .then(
                        data => {
                            commit(SETUP_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },

    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, accounts) {
            state.status = { loaded: true };
            state.items = accounts;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },

        [GET_RECENT_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_RECENT_SUCCESS](state, accounts) {
            state.status = { loaded: true };
            state.recentAccounts = accounts;
        },
        [GET_RECENT_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.recentAccounts = [];
        },

        [SET_SELECTED_ACCOUNT](state, value) {
            state.selectedAccountId = value;
        },

        [GET_REFERENCE_VALUES_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_REFERENCE_VALUES_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.referenceValues = data;
        },
        [GET_REFERENCE_VALUES_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.referenceValues = blankReferenceValues;
        },

        [GET_FRAMEWORKS_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_FRAMEWORKS_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.frameworks = data;
        },
        [GET_FRAMEWORKS_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.frameworks = [];
        },

        [GET_FLAT_FRAMEWORKS_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_FLAT_FRAMEWORKS_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.flatFrameworks = data;
        },
        [GET_FLAT_FRAMEWORKS_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.flatFrameworks = [];
        },

        [GET_MY_WORKSPACES_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_MY_WORKSPACES_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.myWorkspaces = data;
        },
        [GET_MY_WORKSPACES_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.myWorkspaces = getBlankWorkspaces();
        },
        

        [GET_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_SUCCESS](state, account) {
            state.status = { loaded: true };
            state.currentAccount = account;
            if (!state.currentAccount.address) {
                state.currentAccount.address = getBlankAddress();
            }
        },
        [GET_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentAccount = getBlankAccount();
        },

        [GET_SUBSCRIPTION_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_SUBSCRIPTION_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.currentSubscription = data;
        },
        [GET_SUBSCRIPTION_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentSubscription = getBlankAccountSubscription();
        },


        [INVITE_USER_REQUEST](state) {
            state.status = { loading: true };
        },
        [INVITE_USER_SUCCESS](state, user) {
            state.status = { loaded: true };
        },
        [INVITE_USER_FAILURE](state, error) {
            state.status = { failed: true, error };
        },

        [RESEND_INVITE_REQUEST](state) {
            state.status = { loading: true };
        },
        [RESEND_INVITE_SUCCESS](state, user) {
            state.status = { loaded: true };
        },
        [RESEND_INVITE_FAILURE](state, error) {
            state.status = { failed: true, error };
        },

        [CANCEL_INVITE_REQUEST](state) {
            state.status = { loading: true };
        },
        [CANCEL_INVITE_SUCCESS](state, user) {
            state.status = { loaded: true };
        },
        [CANCEL_INVITE_FAILURE](state, error) {
            state.status = { failed: true, error };
        },

        [REMOVE_USER_REQUEST](state) {
            state.status = { loading: true };
        },
        [REMOVE_USER_SUCCESS](state, user) {
            state.status = { loaded: true };
        },
        [REMOVE_USER_FAILURE](state, error) {
            state.status = { failed: true, error };
        },

        [SETUP_REQUEST](state) {
            state.status = { loading: true };
        },
        [SETUP_SUCCESS](state, account) {
            state.status = { loaded: true };
            state.currentAccount = account;
            if (!state.currentAccount.address) {
                state.currentAccount.address = getBlankAddress();
            }
        },
        [SETUP_FAILURE](state, error) {
            state.status = { failed: true, error };
            //state.currentApplication = getBlankApplication();
        },


        [SWITCH_SUBSCRIPTION_REQUEST](state) {
            state.status = { loading: true };
        },
        [SWITCH_SUBSCRIPTION_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.currentSubscription = data;
        },
        [SWITCH_SUBSCRIPTION_FAILURE](state, error) {
            state.status = { failed: true, error };
        },

        [SET_PAYMENT_METHOD_REQUEST](state) {
            state.status = { loading: true };
        },
        [SET_PAYMENT_METHOD_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.currentSubscription = data;
        },
        [SET_PAYMENT_METHOD_FAILURE](state, error) {
            state.status = { failed: true, error };
        },

        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentAccount, {
                [field]: value
            });
        },
        [UPDATE_ADDRESS](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentAccount.address, {
                [field]: value
            });
        },

        [ADD_DOMAIN](state) {
            var newEntry = getBlankDomain();
            newEntry.accountDomainId = uuidv4();
            state.currentAccount.accountDomains.push(newEntry);
            state.currentAccountDomain = newEntry;
        },
        [SAVE_DOMAIN](state, domain) {
            if (domain.accountDomainId == GUID_EMPTY) {
                domain.accountDomainId = uuidv4();
            }
            if (state.currentAccount.accountDomains.find(i => i.accountDomainId == domain.accountDomainId)) {
                Vue.set(state.currentAccount.accountDomains, 
                        state.currentAccount.accountDomains.findIndex(i => i.accountDomainId == domain.accountDomainId),
                        domain);
            } else {
                state.currentAccount.accountDomains.push(domain);
            }

            state.currentAccountDomain = domain;
        },
        [REMOVE_DOMAIN](state, accountDomainId) {
            state.currentAccountDomain = getBlankDomain();
            _.remove(state.currentAccount.accountDomains, { 'accountDomainId': accountDomainId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentAccount.accountDomains = state.currentAccount.accountDomains.slice(0);
        },
        [SET_CURRENT_DOMAIN](state, domain) {
            state.currentAccountDomain = domain;
        },
        [CLEAR_CURRENT_DOMAIN](state) {
            state.currentAccountDomain = getBlankDomain();
        },
        [UPDATE_CURRENT_DOMAIN](state, { field, value }) {
            Object.assign(state.currentAccountDomain, {
                [field]: value
            });
        },


    }
}
