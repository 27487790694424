// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';
import { v4 as uuidv4 } from 'uuid';

export const accountService = {
    getReferenceValues,
    getFrameworks,
    getFlatFrameworks,
    getAll,
    getRecent,
    inviteUser,
    resendInvite,
    cancelInvite,
    removeUser,
    getCurrent,
    getAccountSubscription,
    getUsage,
    checkWorkspace,
    getWorkspaces,
    createWorkspace,
    resetWorkspace,
    createProvider,
    update,
    updateDomains,
    updateTrial,
    updateDemo,
    updateCertifiedProvider,
    updateBillingDay,
    updateBillingMethod,
    updateMinBilledUsers,
    updateMaxBilledUsers,
    updateMaxEditUsers,
    updateCertifiedProviderAddUsers,
    updateChildAccountsBilling,
    updateProviderMargin,
    copyAccount,
    deleteAccount,
    setupSubscriptionPlan,
    setupSubscriptionOnboardingInvoice,
    setupSubscriptionDiscount,
    setupSubscriptionBillingDetails,
    setupSubscriptionPayment,
    setupSubscriptionComplete,
    setupAccount,
    setupPortfolio,
    setupApplications,
    setupAreas,
    setupContacts,
    setupSkip,
    switchSubscriptionPlan,
    setPaymentMethod
};

function getReferenceValues(id) {
    //return axios.get(`/accounts/${id}/applicationreferencevalues`).then(handleResponse);
    return axios.get(`/accounts/referencevalues`).then(handleResponse);
}

function getFrameworks() {
    return axios.get(`/accounts/frameworks`).then(handleResponse);
}
function getFlatFrameworks() {
    return axios.get(`/accounts/flatframeworks`).then(handleResponse);
}



function getAll() {
    return axios.get(`/accounts/`).then(handleResponse);
}

function getRecent() {
    return axios.get(`/accounts/recent`).then(handleResponse);
}

function getCurrent() {
    return axios.get(`/accounts/current`).then(handleResponse);
}

function getAccountSubscription() {
    return axios.get(`/accounts/currentsubscription`).then(handleResponse);
}

function getUsage() {
    return axios.get(`/accounts/usage`).then(handleResponse);
}


function checkWorkspace(workspace, isNewWorkspace) {
    return axios.get(`/accounts/checkworkspace/${encodeURIComponent(workspace)}?isNewWorkspace=${!!isNewWorkspace}&x=${uuidv4()}`).then(handleResponse);
}

function getWorkspaces() {
    return axios.get(`/accounts/workspaces`).then(handleResponse);
}

function createProvider(company, workspaceName, emailAddress, firstName, lastName, countryId, province, postalCode, manualBill, annualBilling, billingEmailAddress, subscriptionLevel) {
    let payload = { company, workspaceName, emailAddress, firstName, lastName, countryId, province, postalCode, manualBill, annualBilling, billingEmailAddress, subscriptionLevel };

    return axios.post('/accounts/providers', payload).then(handleResponse);
}

function createWorkspace(company, workspaceName, emailAddress, firstName, lastName, refCompanyEmployeeId, sampleData, demoAccount, trialAccount, billingEmailAddress, subscriptionLevel, parentAccountId, parentAccountHandlesBilling) {
    let payload = { company, workspaceName, emailAddress, firstName, lastName, refCompanyEmployeeId, sampleData, demoAccount, trialAccount, billingEmailAddress, subscriptionLevel, parentAccountId, parentAccountHandlesBilling };

    return axios.post('/accounts', payload).then(handleResponse);
}

function resetWorkspace(accountId, useSampleData) {
    let payload = { accountId, sampleData: useSampleData };

    return axios.post('/accounts/reset', payload).then(handleResponse);
}

function update(account, file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("accountData", JSON.stringify(account));

    return axios.put(`/accounts/${account.accountId}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);
}

function updateDomains(domains) {
    return axios.put(`/accounts/domains`, domains).then(handleResponse);
}

function updateTrial(account) {
    return axios.put(`/accounts/trial`, { isTrialAccount: account.isTrialAccount, trialExpiryDate: account.trialExpiryDate } ).then(handleResponse);
}

function updateDemo(account) {
    return axios.put(`/accounts/demo`, { isDemoAccount: account.isDemoAccount } ).then(handleResponse);
}

function updateCertifiedProvider(account) {
    return axios.put(`/accounts/certifiedprovider`, { parentAccountId: account.parentAccountId } ).then(handleResponse);
}

function updateBillingDay(account) {
    return axios.put(`/accounts/billingday`, { billingPeriodStartDay: account.billingPeriodStartDay } ).then(handleResponse);
}

function updateBillingMethod(account) {
    return axios.put(`/accounts/billingmethod`, { isManualBill: account.isManualBill } ).then(handleResponse);
}

function updateMinBilledUsers(account) {
    return axios.put(`/accounts/billingminbilledusers`, { minBilledEditUsers: account.minBilledEditUsers } ).then(handleResponse);
}


function updateMaxBilledUsers(account) {
    return axios.put(`/accounts/billingmaxbilledusers`, { maxBilledEditUsers: account.maxBilledEditUsers } ).then(handleResponse);
}

function updateMaxEditUsers(account) {
    return axios.put(`/accounts/maxeditusers`, { maxEditUserAccounts: account.maxEditUserAccounts } ).then(handleResponse);
}

function updateCertifiedProviderAddUsers(account) {
    return axios.put(`/accounts/certifiedprovideraddusers`, { onlyCertifiedProviderCanAddEditUsers: account.onlyCertifiedProviderCanAddEditUsers, onlyCertifiedProviderCanAddViewUsers: account.onlyCertifiedProviderCanAddViewUsers } ).then(handleResponse);
}

function updateChildAccountsBilling(account) {
    return axios.put(`/accounts/billchildaccountsdirectly`, { billChildAccountsDirectly: account.billChildAccountsDirectly } ).then(handleResponse);
}

function updateProviderMargin(account) {
    return axios.put(`/accounts/providermargin`, { providerMarginRate: account.providerMarginRate } ).then(handleResponse);
}

function copyAccount(companyName) {
    return axios.post('/accounts/copy', { company: companyName }).then(handleResponse);
}

function deleteAccount(account) {
    return axios.delete(`/accounts/${account.accountId}`).then(handleResponse);
}

function inviteUser(firstName, lastName, emailAddress, company, position, contactPhone, roleName, portfolioRoles) {
    return axios.post('/accounts/inviteuser', { firstName, lastName, emailAddress, company, position, contactPhone, roleName, portfolioRoles }).then(handleResponse);
}

function resendInvite(userId) {
    return axios.post('/accounts/resendinvite', {userId}).then(handleResponse);
}
function cancelInvite(userId) {
    return axios.post('/accounts/cancelinvite', {userId}).then(handleResponse);
}

function removeUser(userId) {
    return axios.post('/accounts/removeuser', { userId }).then(handleResponse);
}



// setup subscription
function setupSubscriptionPlan(planCode, annualPricing) {
    return axios.post(`/accounts/setup_subscription_plan`, { planCode, annualPricing }).then(handleResponse);
}
function setupSubscriptionOnboardingInvoice(invoiceOnboarding) {
    return axios.post(`/accounts/setup_subscription_onboarding_invoice`, { invoiceOnboarding }).then(handleResponse);
}
function setupSubscriptionDiscount(discountCode) {
    return axios.post(`/accounts/setup_subscription_discount`, { couponCode: discountCode }).then(handleResponse);
}
function setupSubscriptionBillingDetails(account) {
    return axios.post(`/accounts/setup_subscription_billing_details`, account).then(handleResponse);
}
function setupSubscriptionPayment(paymentIntent) {
    return axios.post(`/accounts/setup_subscription_payment`, paymentIntent).then(handleResponse);
}
function setupSubscriptionComplete() {
    return axios.post(`/accounts/setup_subscription_complete`).then(handleResponse);
}


// setup wizard
function setupAccount(account) {
    return axios.post(`/accounts/setup_account`, account).then(handleResponse);
}
function setupPortfolio(portfolio) {
    return axios.post(`/accounts/setup_portfolio`, portfolio).then(handleResponse);
}
function setupApplications(applications) {
    return axios.post(`/accounts/setup_applications`, applications).then(handleResponse);
}
function setupAreas(areas) {
    return axios.post(`/accounts/setup_areas`, areas).then(handleResponse);
}
function setupContacts(contacts) {
    return axios.post(`/accounts/setup_contacts`, contacts).then(handleResponse);
}
function setupSkip() {
    return axios.post(`/accounts/setup_skip`).then(handleResponse);
}

// account maintenance
function switchSubscriptionPlan(planCode, annualPricing) {
    return axios.post(`/accounts/switch_subscription_plan`, { planCode, annualPricing }).then(handleResponse);
}

function setPaymentMethod(setupIntent) {
    return axios.post(`/accounts/set_payment_method`, setupIntent).then(handleResponse);
}
