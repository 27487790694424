import { 
    RIGHT_EDIT_BUSINESS_FACTORS,
    RIGHT_EDIT_TECHNOLOGY_FACTORS,
    RIGHT_EDIT_TAGS,
    RIGHT_EDIT_APPLICATION_STATES,
    RIGHT_EDIT_APPLICATION_TYPES,
    RIGHT_EDIT_TECHNOLOGY_TYPES,
    RIGHT_EDIT_INTEGRATION_TYPES,
    RIGHT_EDIT_CLASSIFICATIONS,
    RIGHT_EDIT_COMPUTING_TYPES,
    RIGHT_EDIT_PLATFORMS,
    RIGHT_EDIT_CAPABILITY_SETS,
    RIGHT_DELETE_CAPABILITY_SETS,
    RIGHT_EDIT_PROJECT_TYPES,
    RIGHT_EDIT_PROJECT_STAGES,
    RIGHT_EDIT_PROJECT_STATUSES,
    RIGHT_EDIT_DRIVERS,
    RIGHT_EDIT_PROGRAMS
} from "@/helpers";

export default [
    { name: 'Overview', icon: 'nc-icon nc-app-services-2', path: '/overview', inWorkspace: true, inProvider: false },
    { name: 'Portfolios', icon: 'nc-icon nc-books', path: '/portfolios', inWorkspace: true, inProvider: false },
    { name: 'Ideas', icon: 'fa fa-lightbulb-o', path: '/ideas', inWorkspace: true, inProvider: false },
    { name: 'Projects', icon: 'nc-icon nc-blueprint', path: '/projects', inWorkspace: true, inProvider: false },
    { name: 'Programs', icon: 'fa fa-tasks', path: '/programs', inWorkspace: true, inProvider: false },
    { name: 'Applications', icon: 'nc-icon nc-support', path: '/applications', inWorkspace: true, inProvider: false },
    { name: 'IT Services', icon: 'fa fa-desktop', path: '/itservices', inWorkspace: true, inProvider: false },
    { name: 'Contacts', icon: 'nc-icon nc-multiple-19', path: '/contacts', inWorkspace: true, inProvider: false },
    // { name: 'Capabilities', icon: 'fa fa-cogs', path: '/capabilities', right: RIGHT_EDIT_CAPABILITY_SETS, inWorkspace: true, inProvider: false },
    { name: 'Analytics', icon: 'fa fa-line-chart', path: '/analytics', inWorkspace: true, inProvider: false },
    // show if not in a workspace
    { name: 'Workspaces', icon: 'fa fa-building-o', path: '/admin/workspaces', inWorkspace: false, inProvider: true },
    // also show it in a provider workspace
    { name: 'Namespace', icon: 'fa fa-building-o', path: '/admin/workspaces', inWorkspace: true, inProvider: true },
    // { name: 'Areas', icon: 'nc-icon nc-hierarchy-53', path: '/areas' },
    // { name: 'Tags', icon: 'nc-icon nc-tags-stack', path: '/tags', right: RIGHT_EDIT_TAGS },
    // { 
    //     name: 'Admin', 
    //     icon: 'nc-icon nc-cogwheel', 
    //     children: [
    //         { 
    //             name: 'Factor Setup', 
    //             //icon: 'nc-icon nc-configuration-tool', 
    //             path: '/factors', 
    //             right: RIGHT_EDIT_BUSINESS_FACTORS 
    //         },
    //         // {
    //         //     name: 'Profile',
    //         //     path: '/admin/profile'
    //         // },
    //         {
    //             name: 'Manage Settings',
    //             path: '/admin/settings'
    //         },
    //         // {
    //         //     name: 'Account',
    //         //     path: '/admin/account'
    //         // },
    //         // {
    //         //     name: 'Application States',
    //         //     path: '/admin/applicationstates',
    //         //     right: RIGHT_EDIT_APPLICATION_STATES
    //         // },
    //         // {
    //         //     name: 'Application Types',
    //         //     path: '/admin/applicationtypes',
    //         //     right: RIGHT_EDIT_APPLICATION_TYPES
    //         // },
    //         // {
    //         //     name: 'Capability Sets',
    //         //     path: '/admin/capabilities',
    //         //     right: RIGHT_DELETE_CAPABILITY_SETS
    //         // },
    //         // {
    //         //     name: 'Classifications',
    //         //     path: '/admin/classifications',
    //         //     right: RIGHT_EDIT_CLASSIFICATIONS
    //         // },
    //         // {
    //         //     name: 'Computing Types',
    //         //     path: '/admin/computingtypes',
    //         //     right: RIGHT_EDIT_COMPUTING_TYPES
    //         // },
    //         // {
    //         //     name: 'Drivers',
    //         //     path: '/admin/drivers',
    //         //     right: RIGHT_EDIT_DRIVERS
    //         // },
    //         // {
    //         //     name: 'Integration Methods',
    //         //     path: '/admin/integrationtypes',
    //         //     right: RIGHT_EDIT_INTEGRATION_TYPES
    //         // },
    //         // {
    //         //     name: 'Platforms',
    //         //     path: '/admin/platforms',
    //         //     right: RIGHT_EDIT_PLATFORMS
    //         // },
    //         // {
    //         //     name: 'Programs',
    //         //     path: '/admin/programs',
    //         //     right: RIGHT_EDIT_PROGRAMS
    //         // },
    //         // {
    //         //     name: 'Project Stages',
    //         //     path: '/admin/projectstages',
    //         //     right: RIGHT_EDIT_PROJECT_STAGES
    //         // },
    //         // {
    //         //     name: 'Project Statuses',
    //         //     path: '/admin/projectstatuses',
    //         //     right: RIGHT_EDIT_PROJECT_STATUSES
    //         // },
    //         // {
    //         //     name: 'Project Types',
    //         //     path: '/admin/projecttypes',
    //         //     right: RIGHT_EDIT_PROJECT_TYPES
    //         // },
    //         // {
    //         //     name: 'Technology Types',
    //         //     path: '/admin/technologytypes',
    //         //     right: RIGHT_EDIT_TECHNOLOGY_TYPES
    //         // },

    //     ] 
    // }
//   {
//     name: 'Dashboard',
//     icon: 'nc-icon nc-bank',
//     path: '/admin/overview'
//   },
//   {
//     name: 'Pages',
//     icon: 'nc-icon nc-book-bookmark',
//     children: [
//       {
//         name: 'Timeline',
//         path: '/pages/timeline'
//       },
//       {
//         name: 'User Profile',
//         path: '/pages/user'
//       },
//       {
//         name: 'Login',
//         path: '/login'
//       },
//       {
//         name: 'Register',
//         path: '/register'
//       },
//       {
//         name: 'Lock',
//         path: '/lock'
//       }
//     ]
//   },
//   {
//     name: 'Components',
//     icon: 'nc-icon nc-layout-11',
//     children: [{
//       name: 'Buttons',
//       path: '/components/buttons'
//     },
//     {
//       name: 'Grid System',
//       path: '/components/grid-system'
//     },
//     {
//       name: 'Panels',
//       path: '/components/panels'
//     },
//     {
//       name: 'Sweet Alert',
//       path: '/components/sweet-alert'
//     },
//     {
//       name: 'Notifications',
//       path: '/components/notifications'
//     },
//     {
//       name: 'Icons',
//       path: '/components/icons'
//     },
//     {
//       name: 'Typography',
//       path: '/components/typography'
//     }]
//   },
//   {
//     name: 'Forms',
//     icon: 'nc-icon nc-ruler-pencil',
//     children: [{
//       name: 'Regular Forms',
//       path: '/forms/regular'
//     },
//     {
//       name: 'Extended Forms',
//       path: '/forms/extended'
//     },
//     {
//       name: 'Validation Forms',
//       path: '/forms/validation'
//     },
//     {
//       name: 'Wizard',
//       path: '/forms/wizard'
//     }
//     ]
//   },
//   {
//     name: 'Table List',
//     icon: 'nc-icon nc-single-copy-04',
//     collapsed: true,
//     children: [{
//       name: 'Regular Tables',
//       path: '/table-list/regular'
//     },
//     {
//       name: 'Extended Tables',
//       path: '/table-list/extended'
//     },
//     {
//       name: 'Paginated Tables',
//       path: '/table-list/paginated'
//     }
//     ]
//   },
//   {
//     name: 'Maps',
//     icon: 'nc-icon nc-pin-3',
//     children: [{
//       name: 'Google Maps',
//       path: '/maps/google'
//     },
//     {
//       name: 'Full Screen Maps',
//       path: '/maps/full-screen'
//     },
//     {
//       name: 'Vector Maps',
//       path: '/maps/vector-map'
//     }
//     ]
//   },
//   {
//     name: 'Widgets',
//     icon: 'nc-icon nc-box',
//     path: '/admin/widgets'
//   },
//   {
//     name: 'Charts',
//     icon: 'nc-icon nc-chart-bar-32',
//     path: '/charts'
//   },
//   {
//     name: 'Calendar',
//     icon: 'nc-icon nc-calendar-60',
//     path: '/calendar'
//   },
]
