<template>
  <div 
      class="text-center text-white"
      :class="{ 'bg-success': isDemo, 'bg-warning': isTrial }" 
      v-if="(isTrial || isDemo) && !isTrialOverride">
        {{ bannerText }}
        <span v-if="isTrial && !trialExpired && trialExpiryDays != null && trialExpiryDays < 6"> - {{ trialExpiryDaysDescription }}</span>
        <span v-if="trialExpired"> - Expired <a href="mailto:getinfo@getinsync.ca" class="underline-link text-white ps-2">Contact us to sign up or extend your trial</a></span>
    </div>
</template>
<style lang="scss">

</style>
<script>
  import { mapState } from "vuex";

  export default {
    components: {
    },
    computed: {
        ...mapState("account", {
            currentAccount: state => state.currentAccount
        }),
        isTrialOverride() {
          return this.currentAccount && this.currentAccount.accountId == '9c41dff6-fb33-45ca-f1bd-08dcb65d9fde';
        },
        isTrial() {
          return this.currentAccount && this.currentAccount.isTrialAccount;
        },
        trialExpiryDays() {
          // check if this.currentAccount.trialExpiryDate is within 7 days
          if (this.currentAccount && this.currentAccount.trialExpiryDate) {
            const expiryDate = new Date(this.currentAccount.trialExpiryDate);
            const today = new Date();
            const diffTime = expiryDate - today;
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
          }
          return null;
        },
        trialExpiryDaysDescription() {
          if (this.trialExpiryDays != null && this.trialExpiryDays < 6) {
            if (this.trialExpiryDays == 1)
              return `Ending in 1 day`;
            return `Ending in ${this.trialExpiryDays} days`;
          }
          return null;
        },
        trialExpired() {
          return this.isTrial && this.currentAccount && this.currentAccount.isTrialExpired;
        },
        isDemo() {
          return this.currentAccount && this.currentAccount.isDemoAccount;
        },
        bannerText() {
          if (this.isTrial) {
            return 'Trial Workspace';
          }
          if (this.isDemo) {
            return 'Demo Workspace';
          }
          return null;
        }
    }
  }

</script>
